header {
  padding: 0 2rem;
  width: 100%;
  max-width: 100%;
  background-color: var(--pink);
  font-size: 2rem;
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
}

.logoWrapper > img.logo {
  position: fixed;
  top: 2rem;
  left: 2rem;
  flex-grow: 1;
  margin: auto;
  z-index: 99999;
  width: 25%;
}

/* If scrolled below 200px, make logo smaller  */
/* .logoWrapper > img.logo.small {
  width: 20%;
} */

/* .logoWrapper > img.logo.large {
  width: 30%;
} */

nav {
  display: flex;
  justify-content: flex-end;
  max-width: 70%;
  align-items: flex-start;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 2rem;
  color: var(--blue);
  text-transform: uppercase;
  font-size: 2rem;
}

nav > ul {
  margin-block-end: 0;
  /* margin-top: 0; */
}

.nav-item {
  display: flex;
  /* width: 30%; */
}

.nav-item > li {
  width: 33%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-inline-end: 2rem;
  font-family: var(--font-bold);
  cursor: pointer;
}

.nav-item > li:hover {
  color: var(--yellow);
}

/* .current {
  color: var(--yellow);
  font-size: 3rem;
} */

.facesolo {
  display: none;
}

@media (max-width: 768px) {
  header {
    padding: 1.5rem;
    min-height: fit-content;
    font-size: 1.3rem;
    justify-content: space-between;
    /* text-transform: uppercase; */
    font-family: var(--font-reg);
    color: var(--blue);
    background-color: transparent;
  }

  nav {
    max-width: 100%;
    font-size: 1.6rem;
    padding: 0;
  }

  .logoWrapper > img.logo {
    max-width: 100px;
    display: none;
  }

  nav > ul {
    padding: 1rem;
    margin-block-end: 0;
  }

  .nav-item {
    padding: 0;
    flex-wrap: wrap;
  }

  .nav-item > li {
    width: inherit;
    margin-inline-end: 1rem;
    padding-right: 1rem;
    border-right: 1px solid var(--blue);
  }

  .current {
    color: var(--yellow);
    font-size: 2rem;
  }

  header > a > h1 {
    margin-bottom: 0;
    text-align: right;
  }

  header > a.title {
    max-width: 50%;
  }

  #hamburger-button {
    padding: 0;
  }

  ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
    background-color: var(--blue);
    top: 20%;
    /* margin-top: 2rem; */
  }

  .MuiPaper-root {
    box-shadow: none;
    background-color: var(--blue) !important;
    border: none;
  }

  .facesolo {
    display: initial;
    max-width: 50px;
  }
  .treatments {
    display: flex;
    flex-direction: column;
  }
}

/* TABLET STYLES  */
@media (min-width: 768px) and (max-width: 992px) {
  .logoWrapper > img.logo {
    max-width: 30vw;
  }

  header > nav {
    width: 50vw;
  }

  nav > .nav-item {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .nav-item > li {
    justify-content: flex-end;
    text-align: right;
    width: fit-content;
  }

  .nav-item > li.bump {
    width: 50%;
  }

  .bump {
    order: 10;
  }
}
