.description {
  font-size: 3rem;
  margin-block-end: 3rem;
  padding: 2rem;
  width: 40%;
  text-align: center;
}

img.bella {
  width: 60%;
}

@media (max-width: 768px) {
  img.bella {
    width: 100%;
    margin-block-end: 2rem;
  }

  .description {
    width: 80%;
  }

  .description > p {
    font-size: 1.7rem;
    margin-block-end: 3rem;
    padding: 0;
    width: 100%;
    line-height: 2rem;
    /* text-align: center; */
  }

  .homeMenu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1.5rem;
    align-items: center;
  }
  .homeMenuItem {
    background-color: var(--orange);
    text-transform: uppercase;
    font-family: var(--font-bold);
    font-size: 1.8rem;
    color: var(--blue);
    padding: 8px;
    transform: skewY(-2deg);
  }

  .homeMenuItem:active {
    color: var(--yellow);
  }

  .homeLogoWrapper {
    /* max-width: 400px; */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "a";
    margin-block-end: 2rem;
    width: 90%;
  }
  .logo.mob.inner {
    grid-area: a;
    width: 95%;
    margin: auto;
  }

  .logo.mob.text {
    grid-area: a;
  }

  .social.logo.home {
    max-width: 2.5rem;
    position: fixed;
    bottom: 1rem;
    left: 1rem;
  }
}
