header.landing {
  background-color: var(--blue);
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  color: var(--pink);
}

span.massage {
  color: var(--yellow);
}

.landingWrapper {
  display: flex;
  background-color: var(--blue);
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

span.massage {
  color: var(--yellow);
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #002bab;
}

.taglineWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tagline {
  font-family: "SouvenirBold";
  color: var(--pink);
  font-size: 8rem;
}

.hero > a > img.face {
  max-width: 25%;
  align-self: flex-end;
  margin-top: -2rem;
}

.navFace {
  display: flex;
  justify-content: end;
}

a:hover {
  color: var(--yellow);
}

@media (max-width: 800px) {
  header.landing > a > h1.title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
