.logoWrapper.home > img.logo {
  width: 100%;
  max-width: 400px;
}

@media (max-width: 768px) {
  header {
    padding: 1.5rem;
    min-height: fit-content;
    font-size: 1.3rem;
    justify-content: space-between;
    /* text-transform: uppercase; */
    font-family: var(--font-reg);
    color: var(--blue);
    background-color: var(--pink);
  }

  header.homeMob {
    position: inherit;
  }

  nav {
    max-width: 100%;
    font-size: 1.6rem;
    padding: 0;
  }

  .logoWrapper > img.logo {
    max-width: 100px;
    display: none;
  }

  nav > ul {
    padding: 1rem;
    margin-block-end: 0;
  }

  .nav-item {
    padding: 0;
    flex-wrap: wrap;
  }

  .nav-item > li {
    width: inherit;
    margin-inline-end: 1rem;
    padding-right: 1rem;
    border-right: 1px solid var(--blue);
  }

  .current {
    color: var(--yellow);
    font-size: 2rem;
  }

  header > a > h1 {
    margin-bottom: 0;
    text-align: right;
  }

  header > a.title {
    max-width: 50%;
  }

  #hamburger-button {
    padding: 0;
    position: fixed;
    top: 1.5rem;
    left: 1.5rem;
  }

  ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
    background-color: var(--blue);
    top: 20%;
    /* margin-top: 2rem; */
  }

  .MuiPaper-root {
    box-shadow: none;
    background-color: var(--blue) !important;
    border: none;
  }

  .facesolo {
    display: initial;
    max-width: 50px;
  }
  .treatments {
    display: flex;
    flex-direction: column;
  }

  header > .description {
    text-align: right;
    padding: 0;
    margin-bottom: 0;
  }
  header > .description > p {
    margin-bottom: 0;
  }
}
/* TABLET STYLES  */
@media (min-width: 768px) and (max-width: 992px) {
  .logoWrapper.home > img.logo {
    max-width: 45vw;
  }
}
