@import "react-alice-carousel/lib/alice-carousel.css";

/* .greenBottleWrapper {
  background-color: aliceblue;
  padding: 1rem;
} */
.greenBottleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  /* width: 50%; */
}

.greenBottleContainer > .bottle {
  max-width: 200px;
}

img.bottle {
  max-width: 200px;
}

.priviledge {
  writing-mode: vertical-rl;
}

.explanation {
  font-size: 1.2rem;
  line-height: 1.4rem;
  max-width: 60%;
  margin: auto;
}

.content-item.bottle > span {
  margin-block-end: 1rem;
}

.content-item.bottle {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

@media (max-width: 768px) {
  .greenBottleContainer {
    display: block;
  }

  .carousel-wrapper {
    display: flex;
    width: 100%;
  }

  .alice-carousel {
    width: 80vw;
  }

  .alice-carousel__next-btn-wrapper {
    display: flex;
    justify-content: flex-start;
  }

  .alice-carousel__prev-btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .explanation {
    font-size: 1.2rem;
    line-height: 1.4rem;
    max-width: 100%;
    margin: auto;
    margin-block-end: 3rem;
  }
  .scale.personal {
    margin-block-end: 3rem;
  }
}
