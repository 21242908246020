main {
  padding: 2rem 2rem 4rem 2rem;
  max-width: 1080px;
  margin: auto;
  margin-right: inherit;

  font-size: 1.8rem;
}

main.home {
  margin-right: auto;
}

section {
  padding: 2rem;
  min-height: 50vh;
  text-align: left;
}

p,
ul > li {
  line-height: 2.5rem;
}

ul > li {
  margin-bottom: 1rem;
}

section.content {
  margin-top: 21vh;
  width: 90%;
}

.content-item {
  width: 100%;
}

section.home.content,
section.contact.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

section.about.content,
section.pricing.content,
section.expect.content,
section.book.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

section.about.content {
  align-items: flex-start;
}
section.testimonials.content {
  margin-top: 0;
  width: 100%;
}

section.skinny {
  max-width: 1080px;
  margin-left: auto;
  /* margin-right: auto; */
}

/* .content-item {
  margin-inline-end: 1.5rem;
} */

.content-item:last-child {
  margin-inline-end: 0;
}

.pageNameMobile {
  display: none;
}

h3.subtitle {
  color: var(--blue);
  font-family: var(--font-bold);
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 900;
}

@media (max-width: 768px) {
  main {
    padding: var(--padding-mob);
    font-size: 1.6rem;
  }
  section {
    padding: 0;
    /* height: 100vh; */
    text-align: left;
  }

  section.content {
    width: 100%;
    margin-top: 30%;
    margin-bottom: 20vh;
  }

  section.home.content,
  section.about.content,
  section.contact.content,
  section.pricing.content {
    flex-direction: column;
  }

  section.home.content {
    justify-content: flex-end;
    display: flex;
    margin-top: inherit;
    margin-bottom: 5vh;
  }

  section.expect.content,
  section.book.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
  }

  section.pricing.content,
  section.contact.content {
    align-items: flex-start;
  }

  section.about.content {
    margin-bottom: 5vh;
  }

  section.contact.content > .content-item:last-child {
    margin-block-end: 20rem;
  }

  .content-item {
    margin-inline-end: 0;
    width: 100%;
  }

  section.skinny {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
  }

  .pageNameMobile {
    display: initial;
    text-transform: uppercase;
    color: var(--yellow);
    font-size: 3rem;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  main {
    max-width: 100vw;
  }

  section {
    min-height: inherit;
  }

  section.content {
    width: 100%;
  }

  section.home {
    min-height: 60vh;
  }

  section.skinny {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
