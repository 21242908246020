.aboutImgContainer {
  display: none;
}

/* .testimonialWrapper {
  display: flex;
  margin-block-end: 3rem;
} */

h3.subtitle.center {
  text-align: center;
  font-size: 2.2rem;
}

p.readMore {
  color: var(--blue);
  font-weight: 900;
  text-align: center;
}

.alice-carousel__stage {
  padding: 2rem !important;
}

.alice-carousel__stage-item {
  padding: 2rem;
}

@media (max-width: 768px) {
  .aboutImgContainer {
    display: inherit;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .testimonialWrapper {
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin: auto;
  }

  .testimonialWrapper > .alice-carousel {
    width: 100%;
  }

  .alice-carousel__stage {
    padding: 0 !important;
  }

  .alice-carousel__stage-item {
    padding: 2rem !important;
  }
}
