form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 2px var(--blue);
  border-radius: 5px;
  padding: 2rem;
  background-color: var(--orange);
}

form > div > label {
  display: flex;
  flex-direction: column;
  width: 100%;
}
form > div {
  width: 100%;
  margin-block-end: 1rem;
}

input,
textarea {
  font-family: var(--font-reg);
  width: 100%;
  padding: 2rem;
  font-size: 1.6rem;
}

form > button {
  font-family: var(--font-bold);
  font-variant: small-caps;
  font-size: 2rem;
  background-color: var(--blue);
  color: #fff;
  border: none;
  padding: 8px;
  margin-block-end: 1rem;
  border-radius: 5px;
  align-self: flex-end;
}

form > button:hover {
  cursor: pointer;
}

form > button:disabled {
  background-color: var(--yellow);
  color: black;
  cursor: initial;
}

.form-control {
  border: none;
  -webkit-box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  border-radius: 4px;
  /* height: 54px; */
  background: #fff;
}

@media (max-width: 768px) {
  input,
  textarea {
    padding: 1rem;
  }
}
