/* footer {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  position: fixed;
  bottom: 0;
  color: var(--blue);
  background-color: aqua;
} */

.footer-item.logo {
  display: flex;
}
.footer-item.logo > a {
  display: flex;
}
.footer-item > a > img.logo {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  max-width: 100px;
  flex-grow: 1;
  /* margin: auto; */
}

.footer-wrapper {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--blue);
}

.footer-wrapper > span {
  display: flex;
  align-items: flex-end;
  margin-block-end: 1rem;
}

.footer-wrapper > span:last-child {
  margin-block-end: 0;
}

.footer-wrapper > span > img.social {
  max-width: 2.5rem;
  margin-inline-end: 1rem;
}

.footer-item > a {
  color: #fff;
}

.footer-wrapper > span > a:hover {
  color: var(--yellow);
}

@media (max-width: 768px) {
  .footer-wrapper {
    flex-direction: row;
    background-color: var(--orange);
    padding: 8px;
    align-items: center;
    left: var(--padding-mob);
    border-radius: 2px;
    transform: skewY(-2deg);
  }
  .footer-item > a > img.logo {
    max-width: 80px;
  }
  .footer-wrapper > span > a > img.social {
    max-width: 2.5rem;
    margin-inline-end: 1rem;
  }
  .footer-wrapper > span {
    margin-block-end: 0;
    align-items: center;
  }
}
