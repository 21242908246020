.testimonialItem {
  background-color: var(--orange);
  padding: 2rem;
  /* max-width: 40%; */
  margin: auto;
  transform: skewY(-2deg);
  margin-inline-end: 3rem;
  height: fit-content;
}

.quotes {
  color: var(--yellow);
  position: relative;
  width: 6rem;
  display: block;
}

.lower.quotes {
  transform: rotate(180deg);
  right: -20px;
  bottom: -30px;
  position: fixed;
}

.upper.quotes {
  top: -30px;
  left: -20px;
  position: fixed;
}

@media (max-width: 768px) {
  .testimonialItem {
    width: 90%;
    margin-block-end: 0rem;
  }
}
