@font-face {
  font-family: "SouvenirMed";
  src: url("/src/assets/fonts/Souvenir-Medium.otf") format("woff2");
}
@font-face {
  font-family: "SouvenirBold";
  src: url("/src/assets/fonts/Souvenir-Bold.otf") format("woff2");
}

:root {
  --pink: #f49ac2;
  --blue: #002bab;
  --yellow: #fff200;
  --orange: #ff6d6d;
  --font-reg: "SouvenirMed";
  --font-bold: "SouvenirBold";
  --padding: 2rem;
  --padding-mob: 1.5rem;
}

body {
  background-color: var(--pink);
  font-family: var(--font-reg);
}

.App {
  text-align: left;
  background-color: var(--pink);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--pink);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.highlighted {
  text-decoration: underline;
}

.highlighted:hover {
  color: var(--yellow);
  cursor: pointer;
}

button.withinText {
  border: none;
  background: none;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  text-decoration: underline;
}
/* @media (max-width: 800px) {
  .App {
    display: none;
  }
} */
